.pageHeader {
  position: relative;
  padding-top: 78.67px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  background-image: url("../images/header_background.webp");
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.pageHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #100b07;
  opacity: 0.7;
}

.pageHeader h1 {
  position: relative;
  color: #fffefe;
  text-transform: uppercase;
  font-size: 24px;
  padding: 0 1rem;
}

@media (width >= 1200px) {
  .pageHeader {
    padding: 0;
  }

  .pageHeader h1 {
    font-size: 30px;
  }
}
