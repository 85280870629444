/* #region mobile footer */
.footer {
  display: none;
}

.footerMobile {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../images/footer_background.webp");
  background-position: center left;
  background-size: auto;
  background-attachment: scroll;
  background-repeat: no-repeat;
  padding: 50px 32px 20px 32px;
  margin-top: auto;
}

.footerMobile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.footerMobile h5 {
  font-size: 24px;
  color: #966a53;
  text-transform: uppercase;
  margin-bottom: 1rem;
  z-index: 1;
}

.footerMobile p {
  color: #fff;
  font-family: "Crimson Text", serif;
  font-size: 18px;
  margin-bottom: 40px;
  text-align: center;
  z-index: 1;
  max-width: 325px;
}

.footerMobile p:last-of-type {
  margin-bottom: 20px;
  z-index: 1;
}

.footerMobile h6 {
  margin-bottom: 0.5rem;
  z-index: 1;
}

.footerMobile h6 a {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  z-index: 1;
}

.footerMobile .socials {
  z-index: 1;
}

.footerMobile .socials img {
  height: 32px;
}

.footerMobile .socialIcon:first-child {
  margin-right: 1rem;
}

/* #endregion  */

@media (width >= 1200px) {
  .footerMobile {
    display: none;
  }

  .footer {
    position: relative;
    width: 100%;
    display: block;
    align-items: center;
    background-image: url("../images/footer_background.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem 0;
  }

  .footer .footerInfo .location {
    display: flex;
    position: relative;
  }

  .footer .footerInfo .location img {
    margin-right: 0.5rem;
  }

  .footer .footerInfo .location p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin: 0;
  }

  .footer .footerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer .footerInfo .description {
    text-align: center;
    z-index: 1;
  }

  .footer .footerInfo .contactNumber {
    z-index: 1;
  }

  .footer .footerNavigation {
    position: relative;
    border-top: #beb1a9 1px solid;
    border-bottom: #beb1a9 1px solid;
    margin-top: 1rem;
  }

  .footer .footerNavigation ul {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin: 1rem auto;
    list-style: none;
    max-width: 800px;
  }

  .footer .footerNavigation ul li a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }

  .footer .footerNavigationSecondary ul {
    display: flex;
    position: relative;
    list-style: none;
    max-width: 500px;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 1rem 0;
  }

  .footer .footerNavigationSecondary ul li a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }

  .footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .footer h5 {
    font-size: 28px;
    color: #f4ac87;
    text-transform: uppercase;
    margin-bottom: 1rem;
    z-index: 1;
  }

  .footer p {
    color: #fff;
    font-family: "Crimson Text", serif;
    font-size: 18px;
    margin-bottom: 40px;
    text-align: center;
    z-index: 1;
    max-width: 500px;
  }

  .footer p:last-of-type {
    margin-bottom: 20px;
    z-index: 1;
  }

  .footer h6 {
    margin-bottom: 0.5rem;
    z-index: 1;
  }

  .footer h6 a {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
    z-index: 1;
  }

  .footer .footerSocials {
    position: relative;
    text-align: center;
    margin-top: 0.5rem;
  }

  .footer .socialIcon:first-child {
    margin-right: 1rem;
  }

  .footer a {
    transition: opacity ease-in-out 200ms;
  }

  .footer a:hover {
    opacity: 0.5;
  }
}
