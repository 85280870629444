.prizeWinners {
  margin: 4rem auto 6rem auto;

  position: relative;
  width: 100%;
  max-width: 1465px;
}

.sectionTitle {
  text-align: center;
  font-size: 28px;
  margin-bottom: 2rem;
  color: #966a53;
}

.prizeWinner {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.prizeWinner img {
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: calc(100% - 75px);
  background-color: #00000099;
  transition: top ease-in-out 250ms, background-color ease-in-out 250ms;
  text-align: center;
  text-overflow: clip;
}

.textContainer h3 {
  color: #fff;
  text-align: center;
  padding: 1rem 1rem;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 2rem;
  font-size: 0.75rem;
  text-transform: none;
}

.prizeWinner:focus-visible {
  outline: none;
}

.prizeWinner:hover .textContainer {
  background-color: #000000dd;
  top: 0;
  justify-content: center;
}

.ponyLink {
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-size: 0.75rem;
  padding: 1rem;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out,
    opacity ease-in-out 500ms;
  background-color: #966a53;
  opacity: 0;
}

.prizeWinner:hover .ponyLink {
  opacity: 1;
}

.textContainer a:hover {
  background-color: #5e9653;
  color: #fff;
}

.imageSlider {
  width: 100% !important;
  margin: 0 auto;
}

.sliderArrowPrevious,
.sliderArrowNext {
  cursor: pointer;
  position: absolute;
  top: 50%;
  border-radius: 2px;
  z-index: 2;
  border: none;
  background-color: #00000055;
  padding: 1rem 0.5rem;
  height: fit-content;
}

.sliderArrowPrevious {
  left: 10px;
}

.sliderArrowNext {
  right: 10px;
}

@media (width >= 381px) {
  .textContainer h3 {
    font-size: 0.8rem;
  }
}

@media (width >= 476px) {
  .textContainer {
    top: calc(100% - 85px);
  }

  .textContainer h3 {
    font-size: 1rem;
  }

  .imageSlider {
    width: 95%;
    margin: 0 auto;
  }
}

@media (width >= 764px) {
  .imageSlider {
    width: 93% !important;
  }

  .sliderArrowPrevious,
  .sliderArrowNext {
    background-color: transparent;
    padding: 0;
    height: fit-content;
  }

  .sliderArrowPrevious {
    left: 10px;
  }

  .sliderArrowNext {
    right: 10px;
  }

  .prizeWinner img {
    padding: 1rem;
  }

  .textContainer {
    margin: 1rem;
    top: calc(100% - 105px);
  }
}

@media (width >= 835px) {
  .imageSlider {
    width: 97%;
    margin: 0 auto;
  }

  .textContainer {
    top: calc(100% - 110px);
  }
}

@media (width >= 1420px) {
  .imageSlider {
    width: unset;
    margin: 0 auto;
  }
}
