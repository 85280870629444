.forSaleSection {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
  place-items: center;
  padding-bottom: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (width >= 696px) {
  .forSaleSection {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width >= 1045px) {
  .forSaleSection {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (width >= 1377px) {
  .forSaleSection {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.forSaleSection .sectionCard {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.forSaleSection .sectionCard img {
  width: 300px;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  margin-bottom: 1rem;
}

.forSaleSection .sectionCard h2 {
  font-size: 24px;
  color: #08080a;
  margin-bottom: 2rem;
  flex-grow: 1;
}

.forSaleSection .sectionCard .btn {
  text-decoration: none;
  padding: 0.5rem 1.25rem;
  text-transform: uppercase;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #1e1615;
  margin-bottom: 3rem;
  transition: background-color 300ms ease-in-out;
}

.forSaleSection .sectionCard .btn:hover {
  background-color: #5e9653;
}

.forSaleSection .queryMessage {
  text-align: center;
  padding: 1rem;
  width: 100%;
  max-width: 500px;
  margin: 2rem auto 0 auto;
  background-color: #966a53;
  grid-column: span 4;
}

.forSaleSection .queryMessage p {
  color: #fff;
}

.separator {
  margin-top: 1rem;
  border-top: #966a53 1px solid;
}
