.newsArticlesSection {
  padding: 4rem 0;
  background-color: #f4f4f4;
}

.sectionHeading {
  font-size: 28px;
  text-align: center;
  margin-bottom: 4rem;
  color: 1e1615;
}

.newsArticlesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.newsArticle {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.imageContainer img {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.textContainer {
  padding: 1.5rem 2rem;
  overflow: hidden;
}

.articleDate {
  color: #a0a0a0;
  font-size: 14px;
  margin-bottom: 1rem;
}

.articleTitle {
  text-transform: none;
  font-size: 22px;
  margin-bottom: 0.5rem;
  color: #08080a;
}

.articleBlurb {
  font-size: 14px;
  color: #3d3d3d;
  margin-bottom: 3rem;
}

.articleButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  border-bottom: #beb1a9 4px solid;
  color: #1e1615;
  text-decoration: none;
  transition: color ease-in-out 250ms;
}

.articleButton:hover {
  color: #beb1a9;
}

.moreNewsLink {
  text-transform: uppercase;
  text-align: center;
  margin-top: 4rem;
}

.moreNewsLink a {
  text-decoration: none;
  font-size: 20px;
  color: #1e1615;
  font-weight: bold;
  transition: color ease-in-out 250ms;
}

.moreNewsLink a:hover {
  color: #966a53;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 1.5rem;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  color: #08080a;
}

.pagination button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 2rem;
  font-size: 2rem;
  color: #966a53;
}

.pagination button:disabled {
  color: #f4f4f4;
  cursor: default;
}

@media (width > 650px) {
  .newsArticle {
    flex-direction: row;
    max-width: 668px;
    max-height: 246px;
  }

  .imageContainer {
    min-width: 50%;
    max-width: 50%;
  }

  .imageContainer img {
    height: 100%;
    max-width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
  }
}
