@import url("https://fonts.googleapis.com/css2?family=Crimson+Text&family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: auto;
}

html,
body {
  height: 100%;
}

#root {
  font-family: "Open Sans", sans-serif;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Crimson Text", serif;
  font-weight: 400;
  text-transform: uppercase;
}

p {
  color: #454545;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
}

.active {
  border-bottom: #beb1a9 4px solid !important;
  font-weight: bold !important;
}
