.hero {
  height: 100%;
  background-color: #100b07;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 78.67px;
}

.heroImageContainer {
  display: flex;
  justify-content: center;
  position: relative;
  height: 50%;
  overflow: hidden;
}

.heroImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.imageGradient {
  position: absolute;
  bottom: 0px;
  height: 33%;
  width: 100%;
  background: linear-gradient(transparent, #100b07);
}

.heroText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  width: 100%;
  padding: 0.5rem 1.5rem;
}

.heroText p {
  color: #fff;
  font-size: 28px;
  text-align: center;
  max-width: 350px;
  font-family: "Crimson Text", serif;
}

.downArrow {
  height: 20px;
  animation: bob 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  margin-bottom: 1.5rem;
}

.introduction {
  padding-top: 41px;
}

.introduction h1 {
  color: #966a53;
  text-align: center;
}

.introduction p {
  margin-top: 25px;
  text-align: center;
  padding: 0 1rem;
}

@keyframes bob {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@media (width >= 360px) {
  .heroText h1 {
    font-size: 28px;
  }
}

@media (width >= 768px) {
  .hero {
    flex-direction: row-reverse;
    height: 500px;
  }

  .heroImageContainer {
    height: 100%;
    width: 100%;
  }

  .imageGradient {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 100%;
    width: 40%;
    background: linear-gradient(to left, transparent, #100b07);
  }

  .imageGradientMobile {
    display: none;
  }

  .heroText {
    justify-content: space-evenly;
    height: 60%;
    margin: auto;
  }

  .heroText p {
    max-width: 750px;
    padding: 0 2rem;
  }

  .downArrow {
    display: none;
  }

  .introduction {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .introduction h1 {
    font-size: 28px;
  }
}

@media (width >= 1200px) {
  .hero {
    padding-top: 0;
  }
}

@media (width >= 410px) {
  .heroText p {
    font-size: 36px;
  }
}
