/* #region horse details */
.horseDetails {
  margin: 1rem;
  padding: 0.25rem;
}

.backLink {
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #1e1615;
  margin-bottom: 2rem;
  cursor: pointer;
}

.backLink .backArrow {
  color: #beb1a9;
  font-family: "Poppins", sans-serif;
}

.horseInfo .imageContainer {
  position: relative;
  width: 100%;
}

.horseInfo .imageContainer .mainImage {
  width: 100%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
}

.horseInfo .imageContainer .magGlassButton {
  background: none;
  cursor: pointer;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
}

.horseInfo .imageContainer .magGlassButton img {
  height: 50px;
  aspect-ratio: 1 / 1;
}

.horseInfo .imageSelector {
  display: flex;
  gap: 5px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
}

.horseInfo .imageSelector::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
}

.horseInfo .imageSelector::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 8px;
  margin: 25px;
}

.horseInfo .imageSelector::-webkit-scrollbar-thumb {
  background-color: #966a53;
  border-radius: 8px;
}

.horseInfo .imageSelector::-webkit-scrollbar-thumb:hover {
  background-color: #966a53dd;
}

.horseInfo .imageSelector img {
  width: 100px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.horseInfo .horseName {
  color: #08080a;
  font-size: 24px;
}

.horseInfo .horseBirthYear {
  color: #966a53;
  font-size: 20px;
  font-family: "Crimson Text", serif;
}

.horseInfo .horseSubHeading {
  color: #454545;
  font-family: "Crimson Text", serif;
  font-size: 16px;
  font-style: italic;
  margin-bottom: 1rem;
}

.horseInfo .horseSire {
  color: #454545;
  font-size: 16px;
}

.horseInfo .horseDam {
  color: #454545;
  font-size: 16px;
}

.horseInfo .horseByStud {
  color: #454545;
  text-transform: capitalize;
  font-size: 16px;
}

.horseInfo .forSaleHorse {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.horseInfo .soldHorse {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: red;
}

.horseInfo hr {
  margin: 2rem 0;
  border-top: #beb1a9 1px solid;
}

.horseInfo .horseDescription {
  white-space: pre-wrap;
  margin-bottom: 2rem;
}

.horseInfo .horseDescription h1 {
  font-size: 28px;
}

.horseInfo .horseDescription p {
  margin-bottom: 0.5rem;
}

.horseInfo .horseDescription ul,
.horseInfo .horseDescription ol {
  margin-left: 1.5rem;
}

.horseInfo .horseDescription a {
  text-decoration: none;
  color: #966a53;
}

/* #endregion */

.horseCTA {
  background-color: #5e9653;
  padding: 1rem 0.5rem;
}

.horseCTA h4 {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.horseCTA a {
  text-decoration: none;
  color: #fff;
}

/* #region breed selector */
.breedSelector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.breedSelector h2 {
  color: #966a53;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
}

.breedSelector ul {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem auto;
}

.breedSelector ul li {
  list-style: none;
}

.breedSelector a {
  text-decoration: none;
  background: none;
  border: none;
  margin: 1rem 0;
  padding: 0 0.5rem 0.25rem 0.5rem;
  text-align: center;
  border-bottom: 3px solid transparent;
  text-transform: uppercase;
  color: #1e1615;
  transition: border-bottom 250ms ease-in-out;
}

.breedSelector a:hover {
  border-bottom: 3px solid #beb1a9;
}
/* #endregion */

@media (width >= 590px) {
  .breedSelector ul li:not(:last-of-type) {
    margin-right: 2rem;
  }
}

@media (width >= 768px) {
  .horseDetails {
    margin: 1rem auto;
    padding: 1rem;
  }

  .horseInfo {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
  }

  .horseInfo .horseTextContent {
    padding: 0 1rem;
  }

  .horseInfo .horseName {
    margin-top: 0;
  }

  .horseCTA h4 {
    font-size: 18px;
  }
}

@media (width >= 1200px) {
  .horseCTA h4 {
    font-size: 24px;
  }
}
