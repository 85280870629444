.fullWidthCTA {
  text-align: center;
  padding: 1.5rem 1rem;
}

.fullWidthCTA h2 {
  max-width: 1000px;
  margin: 0 auto;
}

.fullWidthCTA h2,
.fullWidthCTA a {
  font-size: 16px;
}

.fullWidthCTA a {
  text-decoration: none;
}

@media (width >= 768px) {
  .fullWidthCTA h2,
  .fullWidthCTA a {
    font-size: 18px;
  }
}

@media (width >= 1200px) {
  .fullWidthCTA h2,
  .fullWidthCTA a {
    font-size: 24px;
  }
}
