.header {
  display: none;
}

.navBar {
  display: none;
}

.mobileNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.mobileNav .logo {
  text-decoration: none;
  color: #966a53;
}

.mobileNav .logo h1 {
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}

.mobileNav .menuButton {
  border: none;
  background: none;
  box-shadow: none;
  width: 50px;
}

.mobileNav .menuButton div {
  display: block;
  width: 100%;
  height: 3px;
  background: #966a53;
  transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.mobileNav .menuButton div:not(:last-child) {
  margin-bottom: 7px;
}

.mobileNav .menuButton div:nth-child(2) {
  width: 75%;
}

.menuButton.open div:nth-child(1) {
  transform: rotate(45deg) translate(0px, 13px);
}

.menuButton.open div:nth-child(2) {
  width: 0;
  opacity: 0;
}

.menuButton.open div:nth-child(3) {
  transform: rotate(-45deg) translate(0px, -15px);
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: #000000cc;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: #1e1615 0px 5px 20px 10px;
  transform: translateY(-100%);
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 5;
  height: 100%;
  pointer-events: visible;
}

.menu.open {
  transform: translateY(78.86px);
}

.menuContainer {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  border-top: #966a53 1px solid;
}

.menu .navLinks {
  text-align: center;
  margin-bottom: 1rem;
  list-style: none;
}

.menu .navLinks li:not(:last-child) {
  margin-bottom: 1rem;
}

.menu .navLinks li a {
  text-transform: uppercase;
  color: #1e1615;
  text-decoration: none;
}

.menu .extraLinks {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0 0 0;
  max-width: 256px;
}

.menu .extraLinks li {
  list-style: none;
}

.menu .extraLinks li a {
  text-transform: uppercase;
  text-decoration: none;
  color: #1e1615;
  font-size: 12px;
}

@media (width >= 1200px) {
  .mobileNav,
  .menu {
    display: none;
  }

  .navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2.5rem 0;
    position: sticky;
  }

  .navBar .logo {
    text-decoration: none;
    color: #966a53;
  }

  .navBar .logo h1 {
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
  }

  .navBar .navLinks {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
  }

  .navBar .navLinks li {
    list-style: none;
  }

  .navBar .navLinks li a {
    text-decoration: none;
    text-transform: uppercase;
    color: #1e1615;
    padding: 0.25rem 0.5rem;
    border-bottom: transparent 4px solid;
    transition: border-bottom 300ms ease-in-out;
  }

  .navBar .navLinks li a:hover {
    border-bottom: #beb1a9 4px solid;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0.5rem;
    max-width: 1400px;
    border-bottom: 1px solid #beb1a9;
    background-color: #fff;
  }

  .location {
    display: flex;
    align-items: center;
  }

  .location img {
    margin-right: 0.5rem;
  }

  .location p {
    color: #1e1615;
    font-size: 14px;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
  }

  .links li:not(:last-child) {
    margin-right: 1.5rem;
  }

  .links li a {
    display: flex;
    text-decoration: none;
    text-transform: uppercase;
    color: #1e1615;
    font-size: 14px;
  }
}
