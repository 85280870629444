/* #region breeding lineage */

.breedingLineage {
  margin-bottom: 2rem;
}

.breedingLineage h3 {
  font-size: 24px;
  color: #966a53;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.breedingLineage .tableContainer {
  overflow-x: auto;
  padding-bottom: 2rem;
}

.breedingLineage .tableContainer table {
  margin: 0 auto;
}

.breedingLineage .tableContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
}

.breedingLineage .tableContainer::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 8px;
  margin: 25px;
}

.breedingLineage .tableContainer::-webkit-scrollbar-thumb {
  background-color: #966a53;
  border-radius: 8px;
}

.breedingLineage .tableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #966a53dd;
}

.breedingLineage table {
  overflow-x: auto;
  border-spacing: 5px;
  width: 100%;
}

.breedingLineage td {
  min-width: 200px;
  padding: 0.25rem 0;
  text-align: center;
  border: 1px solid #7f7f7f;
  font-weight: bold;
  text-transform: capitalize;
  background-color: #fff;
}

.breedingLineage td.darkBg {
  background-color: #dcdcdc;
}

@media (width >= 768px) {
  .breedingLineage td {
    padding: 0.5rem 0;
  }
}
/* #endregion */
