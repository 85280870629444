.lightBoxContainer {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000dd;
  z-index: 9999;
}

.lightBoxContainer.visible {
  display: flex;
}

.lightBoxContainer p {
  color: #fff;
}

.imageContainer {
  width: 100%;
  text-align: center;
  position: relative;
  max-width: 1200px;
  max-height: 80%;
}

.closeButton {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  height: 50px;
  aspect-ratio: 1/1;
  border: none;
  color: #fff;
  font-size: 2rem;
  border-radius: 50%;
  text-shadow: #000 0px 0px 3px;
  font-weight: bold;
  cursor: pointer;
}

.imageContainer img {
  width: 100%;
  max-height: 100%;
  object-fit: scale-down;
}

.leftButton,
.rightButton {
  position: absolute;
  border: none;
  background: #966a5399;
  cursor: pointer;
  border-radius: 50%;
  color: #ffffff99;
  font-size: 2.5rem;
  width: 60px;
  font-family: "Poppins";
  transition: opacity 250ms ease-in-out;
}

.leftButton:hover,
.rightButton:hover {
  opacity: 0.8;
}

.leftButton {
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
}

.rightButton {
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}
