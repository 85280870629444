.classificationSelector {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.classificationSelector button {
  background: none;
  border: none;
  margin: 0.25rem 1rem;
  padding: 0 0.5rem 0.25rem 0.5rem;
  text-align: center;
  border-bottom: 3px solid transparent;
  text-transform: uppercase;
  color: #1e1615;
  cursor: pointer;
  transition: border-bottom ease-in-out 250ms;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.classificationSelector button:hover {
  border-bottom: 3px solid #beb1a9;
}

.classificationSelector button.active {
  border-bottom: 3px solid #beb1a9;
}

.separator {
  margin-top: 1rem;
  border-top: #966a53 1px solid;
}

@media (width >= 551px) {
  .classificationSelector button {
    margin: 1rem;
  }
}
