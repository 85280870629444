.ForSaleHeading {
  font-size: 24px;
  color: #966a53;
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}

.ForSaleSection {
  display: flex;
  overflow-x: auto;
  margin-bottom: 2rem;
  scrollbar-color: #966a53 #ebebeb;
  scrollbar-width: thin;
}

@media (width >= 696px) {
  .ForSaleSection {
    display: grid;
    grid-auto-flow: dense;
    place-items: center;
    grid-template-columns: 1fr 1fr;
  }

  .ForSaleSection .card {
    height: 100%;
  }
}

@media (width >= 1045px) {
  .ForSaleSection {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (width >= 1377px) {
  .ForSaleSection {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.ForSaleSection::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
}

.ForSaleSection::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 8px;
  margin: 0 75px;
}

.ForSaleSection::-webkit-scrollbar-thumb {
  background-color: #966a53;
  border-radius: 8px;
}

.ForSaleSection::-webkit-scrollbar-thumb:hover {
  background-color: #966a53dd;
}

.ForSaleSection .card {
  padding: 1rem;
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ForSaleSection .card .ImageLink {
  width: 100%;
}

.ForSaleSection .card img {
  width: 100%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  margin-bottom: 1rem;
}

.ForSaleSection .card h2 {
  font-size: 24px;
  color: #08080a;
}

.ForSaleSection .forSaleHorse {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.ForSaleSection .soldHorse {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: red;
}

.ForSaleSection .card p {
  font-size: 14px;
}

.ForSaleSection .card p:last-of-type {
  margin-bottom: 2rem;
  flex-grow: 1;
}

.ForSaleSection .card .sireDamNames {
  text-transform: capitalize;
}

.ForSaleSection .card .btn {
  text-decoration: none;
  padding: 0.5rem 1.25rem;
  text-transform: uppercase;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #1e1615;
  margin-bottom: 3rem;
  transition: background-color 300ms ease-in-out;
}

.ForSaleSection .card .btn:hover {
  background-color: #5e9653;
}

.loadingCard {
  background-color: #eeeeee55;
  margin-right: 2rem;
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/400% 100%;
  background-repeat: no-repeat;
  animation: shimmer 1.5s infinite ease-in-out;
  font-size: 50px;
  margin-bottom: 2rem;
}

.loadingImage {
  min-width: 300px;
  min-height: 225px;
  background-color: #eee;
  margin-bottom: 1rem;
}

.loadingName {
  background-color: #eee;
  height: 31px;
  width: 250px;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
}

.loadingSire {
  background-color: #eee;
  height: 16px;
  width: 150px;
  margin-bottom: 0.75rem;
  margin-left: 1rem;
}

.loadingDame {
  background-color: #eee;
  height: 16px;
  width: 175px;
  margin-bottom: 0.75rem;
  margin-left: 1rem;
}

.loadingBy {
  background-color: #eee;
  height: 16px;
  width: 200px;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.loadingButton {
  background-color: #eee;
  height: 50px;
  width: 230px;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.queryMessage {
  text-align: center;
  padding: 1rem;
  width: 100%;
  max-width: 500px;
  margin: 2rem auto 0 auto;
  background-color: #966a53;
  grid-column: span 4;
}

.queryMessage p {
  color: #fff;
}

.errorMessage {
  text-align: center;
  padding: 1rem;
  width: 100%;
  max-width: 500px;
  margin: 2rem auto 0 auto;
  background-color: firebrick;
  grid-column: span 4;
}

.errorMessage p {
  color: #fff;
}

@media (width >= 360px) {
  .OurHorseHeading {
    margin-bottom: 1rem;
    font-size: 28px;
  }
  .ForSaleSection .card img {
    width: 100%;
  }
}
