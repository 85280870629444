.recentArticles {
  display: none;
}

.recentArticle {
  display: flex;
  gap: 0.5rem;
  padding: 3px;
}

.recentArticles a {
  text-decoration: none;
}

.recentArticles p {
  color: #966a53;
}

.recentArticles p small {
  color: #1e1615;
}

.recentArticleImageContainer {
  aspect-ratio: 1/1;
}

.recentArticleImageContainer img {
  object-fit: cover;
}

@media (width > 1000px) {
  .recentArticles {
    display: block;
    width: 35%;
  }
}
