.backButtonContainer {
  margin-top: 1rem;
}

.backLink {
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #1e1615;
  margin-bottom: 1rem;
  cursor: pointer;
}

.backLink .backArrow {
  color: #beb1a9;
  font-family: "Poppins", sans-serif;
}

.articleContainer {
  display: flex;
  gap: 4rem;
  justify-content: center;
}

.article {
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.article img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.articleTitle {
  margin: 2rem 0;
  color: #966a53;
  font-size: 28px;
}

.textContent p {
  margin-bottom: 1.5rem;
  color: #1e1615;
}

.textContent :has(img) {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  align-items: center;
}
.textContent :has(img) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textContent ul,
.textContent ol {
  margin-left: 1rem;
}

.textContent li {
  margin-bottom: 1rem;
}

.textContent a {
  text-decoration: none;
  color: #966a53;
  transition: color ease-in-out 250ms;
}

.textContent a:hover {
  color: #1e1615;
}

.textContent blockquote {
  border-left: 5px solid #966a53aa;
  padding-left: 1.5rem;
}

.articleDate {
  color: #966a53;
  text-align: right;
}

@media (width > 600px) {
  .backButtonContainer {
    margin-top: 4rem;
  }
  .article {
    width: 60%;
  }

  .articleTitle {
    font-size: 30px;
  }
}

@media (width > 800px) {
  .textContent :has(img) {
    grid-template-columns: 1fr 1fr;
  }
}
