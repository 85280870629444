.aboutUsSection .sectionHeadText {
  max-width: 1050px;
  text-align: center;
  margin: 2rem auto;
  font-size: 1.25rem;
}

.historiesContainer {
  padding: 1rem;
}

.textContent p {
  margin-bottom: 1rem;
}

.images {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  text-align: center;
}

.images img {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 4/ 3;
  object-fit: cover;
  box-shadow: 2px 2px 3px #00000077;
}

.images p {
  font-family: "Crimson Text";
  color: #966a53;
  font-size: 1.25rem;
  font-style: italic;
}

.images:last-child {
  margin-bottom: 2rem;
}

.infoCTA {
  background-color: #966a53;
  padding: 2rem;
}

.infoCTA > div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.infoCTA > div img {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
}

.infoCTA .infoCTATextContent {
  color: #fff;
  max-width: 900px;
  text-align: center;
}

.sectionFootText {
  padding: 2rem 2rem;
  max-width: 900px;
  text-align: center;
}

@media (width >= 768px) {
  .images {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width >= 1024px) {
  .history {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2rem;
  }

  .history p {
    padding: 0 2rem;
  }

  .images {
    margin-left: 2rem;
  }
  .infoCTA > div {
    flex-direction: row;
  }

  .infoCTA > div img {
    margin-bottom: 0;
    margin-right: 2rem;
  }

  .infoCTA .infoCTATextContent {
    text-align: left;
  }
}
