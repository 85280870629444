.prevNextArticles {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #966a53;
  margin-top: 0.5rem;
  padding: 0;
  padding-top: 1rem;
}

.previousArticle a,
.nextArticle a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.nextArticle a {
  text-align: right;
}

.previousArticle,
.nextArticle {
  display: flex;
}

.nextArticle {
  margin-left: auto;
}

.previousArticle img,
.nextArticle img {
  display: none;
}

.previousArticle img {
  margin-right: 1rem;
}
.nextArticle img {
  margin-left: 1rem;
}

.prevNextText {
  color: #966a53;
}

.prevNextTitle {
  display: none;
}

@media (width >= 530px) {
  .prevNextTitle {
    display: block;
    font-weight: bold;
    font-family: "Crimson Text", serif;
    color: #454545;
    font-size: 18px;
    margin-top: 0.25rem;
  }
}

@media (width >= 850px) {
  .previousArticle img,
  .nextArticle img {
    display: block;
    height: 75px;
    width: 75px;
    object-fit: cover;
    border: 2px solid #966a53;
  }
}
