.ctaButton {
  background-color: #5e9653;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.75rem 1rem;
  transition: background-color 300ms ease-in-out;
  border: 1px solid #5e9653;
  text-shadow: 0px 0px 1px #000;
}

.ctaButton:hover {
  background-color: #100b07;
}

@media (width >= 360px) {
  .ctaButton {
    font-size: 18px;
  }
}
