.genderSelection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.genderSelection button {
  background: none;
  font-size: 16px;
  border: none;
  margin: 1rem;
  padding: 0 0.5rem 0.25rem 0.5rem;
  text-align: center;
  border-bottom: 3px solid transparent;
  text-transform: uppercase;
  color: #1e1615;
  cursor: pointer;
}

.genderSelection button.active {
  border-bottom: 3px solid #beb1a9;
}

.forSaleLinkContainer {
  text-align: center;
  margin-bottom: 4rem;
}

.forSaleLink {
  text-transform: uppercase;
  color: #966a53;
  text-decoration: none;
  font-size: 18px;
  border: 1px solid #966a53;
  padding: 1rem;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
}

.forSaleLink:hover {
  background-color: #966a53;
  color: #fff;
}

.separator {
  margin-top: 1rem;
  border-top: #966a53 1px solid;
}
