.contactUs {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2rem 0;
}

.contactUs h2 {
  color: #966a53;
  margin: 2rem 0 0.5rem 0;
}

.contactDetails p:nth-child(1) {
  color: #454545;
  margin-bottom: 1rem;
}

.contactDetails p span {
  font-weight: 700;
}

.contactDetails p a {
  text-decoration: none;
  color: #454545;
}

.contactDetails .salesText {
  margin-top: 1rem;
  font-weight: bold;
}

.contactDetails ul li {
  list-style: none;
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 30%;
}

.contactForm label {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #966a53;
  text-transform: uppercase;
}

.contactForm input:not([type="submit"]),
.contactForm textarea {
  border: none;
  box-shadow: -1px 1px 1px #966a5377;
  padding: 0.5rem 0.5rem;
  margin-top: 0.25rem;
  min-width: 100%;
}

.contactForm input::placeholder {
  color: #ccc;
}

.contactForm input[type="submit"] {
  background-color: #5e9653;
  color: #fff;
  text-transform: uppercase;
  padding: 0.75rem 1rem;
  margin-top: 1rem;
  border: 1px solid #5e9653;
  font-weight: 700;
  cursor: pointer;
  transition: background-color ease-in-out 300ms;
}

.contactForm input[type="submit"]:hover {
  background-color: #000;
}

@media (width >= 520px) {
  .contactUs {
    flex-direction: row;
  }
}
